import React from 'react'
import Layout from "../components/App/Layout"
import Referents from "../components/Index/Referents.en"
import { Helmet } from "react-helmet"
import Navbar from "../components/App/Navbar.en"
 
const Referent = () => {
    return (
        <Layout>
            <Helmet>
                <title>Wispeek | Referent area</title>
                <meta name="description" content="" />
            </Helmet>
        <Navbar />
        <Referents />
        </Layout>
    );
}

export default Referent;